'use client';

import { PlantModalContent } from '@/components/ui/modals';
import { getTZDate } from '@/lib/getDates';
import { usePlants, useSun } from '@/lib/store';
import { Sparkline } from '@mantine/charts';
import {
    Badge,
    Box,
    Card,
    Center,
    Grid,
    Group,
    SimpleGrid,
    Slider,
    Stack,
    Text,
    ThemeIcon,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconLink, IconMoonFilled, IconSunFilled, IconTool } from '@tabler/icons-react';
import { useSession } from 'next-auth/react';
import { redirect } from 'next/navigation';

// Override console.error
// This is a hack to suppress the warning about missing defaultProps in the recharts library
// @link https://github.com/recharts/recharts/issues/3615
const error = console.error;
console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
};

const calculatePR = (kwp, kwh, temperature, cdt, irradiance, tare) => {
    return kwh / (((((kwp - tare) * (100 - (temperature - 25) * cdt)) / 100) * irradiance) / 1000);
};

const IndexPage = () => {
    useSession({
        required: true,
        onUnauthenticated: () => redirect('/login'),
    });

    const { plants, powerMeasuresData } = usePlants();
    const { sunrise, sunset } = useSun();
    const now = new Date();

    const theme = useMantineTheme();

    return plants.length > 0 ? (
        <SimpleGrid cols={{ base: 2, sm: 3, md: 4, lg: 5 }}>
            {plants.map((plant) => {
                const currentData = powerMeasuresData.find((d) => d.plantId === plant.id)?.data;

                let backgroundColor = 'indigo.9';
                let stateLabel = 'Basso irraggiamento';
                let pr;

                if (plant.maintenance || plant.repairing) {
                    backgroundColor = 'orange';
                    stateLabel = 'In manutenzione';
                } else if (!plant.active) {
                    backgroundColor = 'gray';
                    stateLabel = 'Non attivo';
                } else if (!plant.communicating) {
                    backgroundColor = 'cyan';
                    stateLabel = 'Offline';
                } else if (getTZDate(now) > getTZDate(sunset) || getTZDate(now) < getTZDate(sunrise)) {
                    stateLabel = 'Notte';
                } else if (plant.measures[0]?.alarm) {
                    backgroundColor = 'red';
                    stateLabel = 'Problemi';
                } else if (plant.solarimeter) {
                    if (plant.measures[0]?.irradiance > 100 && plant.measures[0].power > 0) {
                        pr =
                            calculatePR(
                                plant.plantPower,
                                plant.measures[0].power / 1000,
                                plant.measures[0].temperature || 0,
                                plant.temperatureCoefficient,
                                plant.measures[0].irradiance,
                                plant.tare
                            ) * 100;
                        if (pr > plant.targetPerformanceRatio) {
                            backgroundColor = 'teal';
                            stateLabel = 'Attivo';
                        } else {
                            backgroundColor = 'yellow';
                            stateLabel = 'Sotto soglia';
                        }
                    } else if (plant.measures[0]?.power <= 0) {
                        backgroundColor = 'dark';
                        stateLabel = 'No potenza';
                    }
                } else if (!plant.solarimeter) {
                    backgroundColor = 'violet';
                    stateLabel = 'No solarimetro';
                }

                const getBadge = (name, props) => (
                    <Badge size="lg" tt="none" {...props}>
                        {name}
                    </Badge>
                );

                let badge;

                if (plant.apiType.id === 1) {
                    badge = getBadge(plant.apiType.name, {
                        style: { cursor: 'pointer' },
                        component: 'a',
                        href: new URL(plant.url).origin,
                        target: '_blank',
                        leftSection: <IconLink size="1.2rem" />,
                    });
                } else if (plant.apiType.id === 2) {
                    badge = getBadge(plant.apiType.name, {
                        style: { cursor: 'pointer' },
                        component: 'a',
                        href: 'https://monitoring.solaredge.com/solaredge-web/p/login?locale=it_IT',
                        target: '_blank',
                        leftSection: <IconLink size="1.2rem" />,
                    });
                }

                return (
                    <Card
                        onClick={() => {
                            modals.open({
                                title: (
                                    <Group>
                                        <Text fz="xl" pl="md" fw={500}>
                                            {plant.name}
                                        </Text>
                                        {badge}
                                    </Group>
                                ),
                                children: <PlantModalContent powerMeasuresData={currentData} plantId={plant.id} />,
                                size: '85vw',
                            });
                        }}
                        style={{ cursor: 'pointer' }}
                        shadow="sm"
                        padding="lg"
                        radius="md"
                        key={plant.id}
                    >
                        <Card.Section bg={backgroundColor}>
                            <Stack gap={0}>
                                <Grid p={0}>
                                    <Grid.Col p={0} span={4}>
                                        <ThemeIcon mt="md" ml="xl" size="sm" variant="transparent">
                                            {plant.maintenance || plant.repairing ? (
                                                <IconTool fill="white" color="white" />
                                            ) : getTZDate(now) > getTZDate(sunset) ||
                                              getTZDate(now) < getTZDate(sunrise) ? (
                                                <IconMoonFilled color="white" />
                                            ) : (
                                                <IconSunFilled color="white" />
                                            )}
                                        </ThemeIcon>
                                    </Grid.Col>
                                    <Grid.Col p={0} span={4}>
                                        <Text ta="center" mt="md" size="xs" c="white">
                                            {plant.apiType.name}
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col p={0} span={4}></Grid.Col>
                                </Grid>
                                <Grid p={0} mt={-5}>
                                    <Grid.Col p={0} span={4}>
                                        <Text ta="center" mt="md" size="xs" c="white">
                                            {plant.measures.length > 0 && plant.measures[0].irradiance !== null
                                                ? plant.measures[0].irradiance.toFixed(2)
                                                : '-'}{' '}
                                            W/mq
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col p={0} span={4}>
                                        <Text ta="center" mt="md" size="xs" c="white">
                                            {plant.measures.length > 0
                                                ? (plant.measures[0].power / 1000).toFixed(2)
                                                : '-'}{' '}
                                            KW
                                        </Text>
                                    </Grid.Col>
                                    <Grid.Col p={0} span={4}>
                                        <Text ta="center" mt="md" size="xs" c="white">
                                            max: {plant.plantPower} KW
                                        </Text>
                                    </Grid.Col>
                                </Grid>
                                <Center>
                                    {currentData ? (
                                        <Sparkline
                                            mt="lg"
                                            mb="lg"
                                            w={140}
                                            h={80}
                                            bg="#ffffff45"
                                            pl="xs"
                                            pr="xs"
                                            style={{ borderRadius: 10 }}
                                            color="white"
                                            data={currentData.map((d) => d.Power)}
                                        />
                                    ) : (
                                        <Box mt="lg" mb="lg" w={120} h={80}>
                                            <Text ta="center" c="white">
                                                Nessun dato giornaliero
                                            </Text>
                                        </Box>
                                    )}
                                </Center>
                                <Center pb="sm" mt={-10}>
                                    <Slider
                                        style={{ visibility: Boolean(pr) ? 'visible' : 'hidden', cursor: 'pointer' }}
                                        styles={{
                                            bar: { backgroundColor: theme.colors.cyan[3] },
                                            mark: {
                                                backgroundColor: backgroundColor.includes('.')
                                                    ? theme.colors[backgroundColor.split('.')[0]][9]
                                                    : theme.colors[backgroundColor][8],
                                                borderColor: backgroundColor.includes('.')
                                                    ? theme.colors[backgroundColor.split('.')[0]][9]
                                                    : theme.colors[backgroundColor][8],
                                                borderRadius: 0,
                                            },
                                        }}
                                        w={200}
                                        value={pr}
                                        max={100}
                                        marks={[{ value: plant.targetPerformanceRatio }]}
                                        disabled
                                    />
                                </Center>
                            </Stack>
                        </Card.Section>
                        <Card.Section>
                            <Grid mb="md" gutter={0}>
                                <Grid.Col span={2}>
                                    {plant.measures[0]?.alarm > 0 && (
                                        <Center mt="md">
                                            <Badge variant="light" color="red">
                                                {plant.measures[0].alarm}
                                            </Badge>
                                        </Center>
                                    )}
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <Box mt="md">
                                        <Tooltip withArrow label={plant.name} openDelay={500}>
                                            <Title ta="center" lineClamp={1} order={6}>
                                                {plant.name}
                                            </Title>
                                        </Tooltip>
                                        <Text fz="xs" c="dimmed" ta="center">
                                            {stateLabel}
                                        </Text>
                                    </Box>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    {plant.plantNotes.length > 0 && (
                                        <Center mt="md">
                                            <Badge variant="outline" color="yellow">
                                                {plant.plantNotes.length}
                                            </Badge>
                                        </Center>
                                    )}
                                </Grid.Col>
                            </Grid>
                        </Card.Section>
                    </Card>
                );
            })}
        </SimpleGrid>
    ) : (
        <Center>
            <Text fz="lg" c="dimmed">
                Nessun impianto disponibile. Aggiunti un impianto da Spark per proseguire.
            </Text>
        </Center>
    );
};
export default IndexPage;
